import React from "react"
import HeroCard from "./cardGeneric"

import FormContato from "../formContato"

const HeroSection = 
() => {

  return (
    <>
      <HeroCard>
        <h2>Contact Us</h2>
        <div className="mb-4"></div>

        <h4>Spere Financial is located at:</h4>
        <span style={{display: "block"}}>1811 Shady Oaks Suite #190</span>
        <span style={{display: "block"}}>Denton, TX 76205</span>
        <span style={{display: "block"}}>United States</span>
        <span style={{display: "block"}}>(833) 337-7373</span>
        <div className="mb-2"></div>
        <span style={{display: "block"}}>Email us: <a href="mailto:info@sperefinancial.com">info@sperefinancial.com</a></span>
        <div className="mb-5"></div>

        <h4>Leave us a message:</h4>
        <FormContato />
      </HeroCard>
    </>
  )
}

export default HeroSection
