import React, { useState } from "react"

import Recaptcha from "react-recaptcha"

import Form from "react-bootstrap/Form"
import Button from "../atoms/button"

const FormContato = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState("");

  const verifyCallback = function (response) {
    setToken(response);
    setError("");
  };

  const expiredCallback = function () {
    setToken(null);
  };

  const submit = (event) => {
    if (!token) {
      event.preventDefault();
      setError("You need to check the captcha before sending this form");
      return;
    }

    const form = document.contactForm;

    const originalText = form["entry.1757923183"].value
    const textWithoutUrls = originalText.replace(
      /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/g,
      "<url redacted>"
    )
    form["entry.1757923183"].value = textWithoutUrls

    form.action = "https://docs.google.com/forms/u/0/d/e/1FAIpQLScJWgrLPkzApusNuWOBb7YvdsVSfdaQsL5IgntVx8to5WhywQ/formResponse"
    return true
  }

  return (
    <Form
      name="contactForm"
      action=""
      style={{maxWidth: "600px"}}
      onSubmit={submit}
    >
      <Form.Group controlId="formBasicName" style={{maxWidth: "400px"}}>
        <Form.Label>Name <span className="text-danger">*</span></Form.Label>
        <Form.Control name="entry.1941697201" type="text" required />
      </Form.Group>

      <Form.Group controlId="formBasicEmail" className="mb-4" style={{maxWidth: "400px"}}>
        <Form.Label>Email <span className="text-danger">*</span></Form.Label>
        <Form.Control name="entry.1000683326" type="email" required />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="formBasicPhone" style={{maxWidth: "400px"}}>
        <Form.Label>Phone (optional)</Form.Label>
        <Form.Control name="entry.1583289469" type="text" />
      </Form.Group>

      <Form.Group controlId="formBasicCompany" style={{maxWidth: "400px"}}>
        <Form.Label>Company (optional)</Form.Label>
        <Form.Control name="entry.1147234726" type="text" />
      </Form.Group>

      <Form.Group controlId="formBasicDescription">
        <Form.Label>Describe your question (optional)</Form.Label>
        <Form.Control name="entry.1757923183" as="textarea" rows="3" />
      </Form.Group>

      <Recaptcha
        sitekey="6Ldb_9EZAAAAAL-8RN324EDBBdjaJToyI_K23Toh"
        verifyCallback={verifyCallback}
        expiredCallback={expiredCallback}
        className="mt-4 mb-4"
      />

      <p className="text-danger">{error}</p>

      <Button variant="primary" type="submit" className="mt-2 px-4">
        Send
      </Button>
    </Form>
  )
}

export default FormContato
