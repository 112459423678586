import React from "react"

import Layout from "../components/templates/layoutWithTitle"
import SEO from "../components/seo"

import HeroContact from "../components/organisms/heros/contact"

const IndexPage = ({ data }) => (
  <Layout title="" fluid>
    <SEO title="Contact Us" />

    <HeroContact />
  </Layout>
)

export default IndexPage
